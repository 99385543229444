<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >点名认证统计</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlCurr"
          style="align-items: flex-start;"
        >
          <div class="searchbox">
            <div>
              <div
                title="学员姓名"
                class="searchboxItem"
                style="display:flex;align-items: center;margin-right:0"
              >
                <span
                  class="itemLabel"
                  style="min-width: 5rem; text-align: right; font-size: 14px;padding: 0 0.5rem;"
                  >学员姓名:</span
                >
                <el-input
                  v-model="userName"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入学员姓名"
                />
              </div>
              <div
                title="手机号码"
                class="searchboxItem"
                style="display:flex;align-items: center;"
              >
                <span
                  class="itemLabel"
                  style="min-width: 5rem; text-align: right; font-size: 14px;padding: 0 0.5rem;"
                  >手机号码:</span
                >
               <el-input
                    v-model="ruleForm.mobile"
                    type="text"
                    size="small"
                    clearable
                    placeholder="请输入手机号码"
                  />
              </div>
              <transition name="el-zoom-in-center">
                <el-button
                  class="bgc-bv"
                  style="margin-left:10px"
                  round
                  @click="getData()"
                  size="small"
                  v-show="dialogVisible == false"
                  >查询</el-button
                >
              </transition>
              <div style="margin-left:10px;">
                <el-button @click="search()" size="small" type="text">
                  高级搜索
                  <i
                    :class="
                      dialogVisible == true ? 'el-icon-top' : 'el-icon-bottom'
                    "
                  ></i>
                </el-button>
              </div>
            </div>
          </div>
          <el-collapse-transition>
            <div
              class="searchbox"
              style="margin-top:10px"
              v-show="dialogVisible == true"
            >
              <el-form label-width="80px" ref="ruleForm" :model="ruleForm">
                <el-form-item label="身份证号:" prop="source">
                  <el-input
                    v-model="ruleForm.idcard"
                    type="text"
                    size="small"
                    clearable
                    placeholder="请输入身份证号"
                  />
                </el-form-item>
                <el-form-item label="班级名称:" prop="Auditstatus">
                  <el-input
                    v-model="ruleForm.projectName"
                    type="text"
                    size="small"
                    clearable
                    placeholder="请输入班级名称"
                  />
                </el-form-item>
                 <el-form-item label="班级编号:" prop="Salesstatus">
                  <el-input
                    v-model="ruleForm.projectCode"
                    type="text"
                    size="small"
                    clearable
                    placeholder="请输入班级编号"
                  />
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-transition>
          <el-collapse-transition>
            <div
              class="searchbox"
              style="margin-top:8px"
              v-show="dialogVisible == true"
            >
              <el-form label-width="80px" ref="ruleForm" :model="ruleForm">
               
                <el-form-item label="课程名称:" prop="Trainingtype">
                  <el-input
                    v-model="ruleForm.courseName"
                    type="text"
                    size="small"
                    clearable
                    placeholder="请输入课程名称"
                  />
                </el-form-item>
                <el-form-item label="学习状态:" prop="Trainingtype">
                  <el-select
                    class="listInput"
                    size="small"
                    v-model="ruleForm.classStatus"
                    placeholder="请选择学习状态"
                    clearable
                  >
                    <el-option
                      v-for="item in classStatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item style="margin-left:10px;" class="btns">
                  <el-button
                    class="bgc-bv"
                    round
                    @click="getData()"
                    size="small"
                    >查询</el-button
                  >
                  <el-button @click="cancleForm()" type="text"
                    >清空搜索条件</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-transition>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                minWidth="150"
              />
              <el-table-column
                label="手机号码"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                minWidth="120"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="190"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="180"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                minWidth="190"
              />
              <el-table-column
                label="学习状态"
                align="center"
                prop="completeState"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.completeState != '40'">{{
                    $setDictionary("LEARNINGSTATE", scope.row.completeState)
                  }}</span>
                  <el-tooltip
                    v-else
                    class="item"
                    effect="dark"
                    :content="scope.row.notCompleteReasons"
                    placement="top"
                  >
                    <span
                      style="cursor: pointer; display: flex;justify-content: center;"
                      >{{
                        $setDictionary("LEARNINGSTATE", scope.row.completeState)
                      }}</span
                    >
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                label="完成学时"
                align="right"
                show-overflow-tooltip
                prop="studyLessonNum"
              />
              <el-table-column
                label="剩余学时"
                align="right"
                show-overflow-tooltip
                prop="residueLessonNum"
              />
              <el-table-column
                label="监管点名完成次数"
                align="right"
                 width="70"
                show-overflow-tooltip
                prop="govRollCompleteNum"
              />
              <el-table-column
                label="监管点名剩余次数"
                align="right"
                 width="70"
                show-overflow-tooltip
                prop="govRollResidueNum"
              />
              <el-table-column
                label="监管点名超时次数"
                align="right"
                 width="70"
                show-overflow-tooltip
                prop="govRollTimeoutNum"
              />
              <el-table-column
                label="机构点名完成次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="orgRollCompleteNum"
              />
              <el-table-column
                label="机构点名剩余次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="orgRollResidueNum"
              />
              <el-table-column
                label="机构点名超时次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="orgRollTimeoutNum"
              />
              <el-table-column
                label="随机点名完成次数"
                align="right"
                 width="70"
                show-overflow-tooltip
                prop="randomRollCompleteNum"
              />
              <el-table-column
                label="随机点名剩余次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="randomRollResidueNum"
              />
              <el-table-column
                label="随机点名失败次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="randomRollTimeoutNum"
              />

              <el-table-column
                label="点名状态"
                align="center"
                show-overflow-tooltip
                prop="rollCallState"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";

export default {
  name: "operate/RollCallCertificationStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogVisible: false,
      userName: "",
      ruleForm: {
        mobile: "",
        idcard: "",
        projectName: "",
        projectCode: "",
        courseName: "",
        classStatus:'',
      },
      classStatusList: [],
    };
  },
  created() {},
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {
    getLearningstateList() {
      const classStatusList = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in classStatusList) {
        list.push({
          value: key,
          label: classStatusList[key],
        });
      }
      this.classStatusList = list;
    },
    init() {
      this.getTableHeight();
      this.getData(-1);
      this.getLearningstateList();
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
       if (this.ruleForm.mobile) {
        params.mobile = this.ruleForm.mobile;
      }
      if (this.ruleForm.idcard) {
        params.idcard = this.ruleForm.idcard;
      }
      if (this.ruleForm.projectName) {
        params.projectName = this.ruleForm.projectName;
      }
      if (this.ruleForm.projectCode) {
        params.projectCode = this.ruleForm.projectCode;
      }
      if (this.ruleForm.courseName) {
        params.courseName = this.ruleForm.courseName;
      }
      if (this.ruleForm.classStatus) {
        params.completeState = this.ruleForm.classStatus;
      }
      this.doFetch({
        url: "gov/roll/call/user/page-list",
        params,
        pageNum,
      });
    },
    //清空搜索条件
    cancleForm() {
      // this.$refs.tree.clear(); // 高级搜索关闭，清空培训类型数据
      this.ruleForm = {
        mobile:'',
        idcard:'',
        projectName:'',
        projectCode:'',
        courseName:'',
        classStatus:'',
      };
      this.userName = "";
      this.params = {};
    },
    // 高级搜索弹窗
    search() {
      this.dialogVisible = !this.dialogVisible;
      this.getTableHeight();
    },
    /* 获取高度 */
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.dialogVisible == false
            ? 40 + 0.675 * 16 + 1
            : 120 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
